<template>
  <div  elevation="4">
      <v-img
        contain
        src="homepage.jpg"
        ></v-img>
      <div v-if="visitorCount" class="text-center fontInter mt-4 pa-1">You are our visitor number {{visitorCount}}.</div>
    </div>
</template>

<script>
export default {
  props: {visitorCount: {type: [Number, String]}
}
    
  
}
</script>

<style>
.backgroundColor {
  background-color: rgb(243, 243, 242) !important;
}
</style>