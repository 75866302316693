<template>
    <v-container class="mainProducts text-h4 mt-10 text-center backgroundColor" fluid>
        <v-row no-gutters class="backgroundColor">
          <v-col cols="12" lg="4" class="mt-2" v-for="(perfume,index) in items" :key="index">
            <ProductCard :product="perfume" @addToCart="addToCart"></ProductCard>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ProductCard from './ProductCard.vue'

export default {
    name: 'AllProducts',
    components: {ProductCard},
    props: {
        items: {type: Array}
    },
    methods: {
        addToCart(product){
           this.$emit('addToCart', product)
        }
    }

}
</script>

<style>
.backgroundColor {
  background-color: rgb(243, 243, 242);
}
</style>