<template>
  <v-app class="flex d-flex flex-grow-1">
    <v-app-bar
      app
      transparent
      color="white"
      height="100"
    >
      <v-row class="justify-center align-items-center">
        <v-col cols="8" class="headerTitle ml-4">CHER</v-col>
        <v-spacer></v-spacer>
        <v-col class="mt-7 mr-3 text-right pa-0">
          <v-badge  :value="size" overlap color="red" left bottom :content="size">
            <v-btn
              icon
              x-large
              color="black"
              @click="openDialog"
            >
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </v-badge>
        </v-col>
        
      </v-row>       
    </v-app-bar>

    <v-main class="backgroundColor">
      <HomePage @cartSize="changeSize" :dialog="dialog" @closeDialog="closeDialog" :visitorCount="animatedCount" />
    </v-main>
  </v-app>
</template>

<script>
import HomePage from './components/HomePage';

export default {
  name: 'App',
  components: {
    HomePage,
  },
  created(){
    fetch('https://api.counterapi.dev/v1/chergoa/visits/up')
    .then(response => response.json())  // Convert the response to JSON
    .then(data => {
      this.visitorCount = data.count
      this.animateCount()
    })    // Log the data to the console
    .catch(error => console.error('Error:', error));  // Handle any errors

  },
  data: () => ({
    dialog: false,
    size: 0,
    visitorCount: null,
    animatedCount: 0
  }),
  methods: {
    openDialog(){
      this.dialog = true
    },
    closeDialog(){
      this.dialog = false
    },
    changeSize(e){
      this.size = e
    },
    animateCount() {
      let start = 0;
      const end = this.visitorCount;
      const duration = 2000; // Duration of the animation in milliseconds
      const stepTime = 50; // Time between each step in milliseconds
      const steps = duration / stepTime;
      const increment = end / steps;

      this.interval = setInterval(() => {
        start += increment;
        if (start >= end) {
          this.animatedCount = end;
          clearInterval(this.interval);
        } else {
          this.animatedCount = Math.floor(start);
        }
      }, stepTime);
    }
  }
};
</script>
<style>
body{
  font-family: inter;
}
 .headerTitle{
  font-family: mulegh;
  font-size: 65px;
  color: rgb(20, 20, 20)
 }
.backgroundColor {
  background-color: rgb(243, 243, 242);
}
.fontInter {
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
}
</style>
